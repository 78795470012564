* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
	background-color: #141416;
}

a {
	color: inherit;
	text-decoration: none;
}

ul {
	list-style-type: none;
}

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}
}

@media only screen and (min-width: 412px) and (max-width: 768px) {
	html,
	body {
		padding-left: 16px;
		padding-right: 16px;
	}
}
