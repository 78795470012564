.testimonialsContainer {
	text-align: center;
	display: flex;
	flex-direction: column;
	width: 1200px;
}

.testimonials {
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.testimonial {
	width: auto;
	height: auto;
	background-color: #18191d;
	border-color: #353945;
	border-style: solid;
	border-radius: 16px;
	padding: 40px 40px 40px 40px;
	border-width: 1px;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
}

.nameTitle h3 {
	margin-top: 40px;
	margin-bottom: 8px;
}

.testimonial h3 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	text-align: left;
	color: #ffffff;
}

.testimonial h1 {
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 80px;
	line-height: 88px;
	text-align: left;
	color: #ffffff;
}

.testimonial h2 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: #ff9c50;
}

.testimonial p {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	text-align: left;
	color: #ffffff;
}

.quote {
	height: 40px;
	align-self: flex-start;
	margin-bottom: 24px;
}

.testimonial button {
	margin-top: 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
}

.testimonial button:hover {
	background-color: #0056b3;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalContent {
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	text-align: left;
	width: 400px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.modalContent h3 {
	margin: 0 0 10px;
}

.modalContent button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
	float: right;
}

.modalContent button:hover {
	background-color: #0056b3;
}
